export const playerTranslationKeys = {
	audio: 'Audio',
	audioSubtitlesAndCCLabel: 'UI_Player_AduioSubtitlesAndCCLabel',
	chapterImageHide: 'UI_Player_ShowChapterImages',
	chapterImageShow: 'UI_Player_HideChapterImages',
	chapterNumber: 'ChapterNumber',
	close: 'Close',
	closedCaptions: 'UI_Player_Closed_Captions',
	dualPlaybackLayoutViews: 'UI_Player_DualPlaybackLayoutViews',
	fullScreenEnd: 'UI_Player_EndFullScreen',
	fullScreenStart: 'UI_Player_StartFullScreen',
	fullViewContent: 'UI_Player_FullViewContent',
	fullViewSpeaker: 'UI_Player_FullViewSpeaker',
	hide: 'hide',
	hideChapters: 'HideChapters',
	hkMuteUnmute: 'UI_Player_Hotkeys_MuteUnmute',
	hkPlayPause: 'UI_Player_Hotkeys_PlayPause',
	hkStartEndFullScreen: 'UI_Player_Hotkeys_StartEndFullscreen',
	hlsPlaybackMenuOption: 'UI_Player_HLS_Playback_Menu_Option',
	keyboardShortcuts: 'UI_Player_Hotkeys_KeyboardShortcuts',
	live: 'UI_Player_Live',
	loadingPlugin: 'UI_Player_Loading_Plugin',
	mute: 'UI_Player_Mute',
	muted: 'UI_Player_TapToUnmute',
	nextChapter: 'UI_Player_NextChapter',
	normal: 'UI_Player_Normal',
	notVerified: 'UI_Player_VideoVerification_NotVerified',
	off: 'Off',
	ok: 'Ok',
	onlyContent: 'UI_Player_FullViewContent',
	onlySpeaker: 'UI_Player_FullViewSpeaker',
	original: 'Original',
	originalPlaybackMenuOption: 'UI_Player_Original_Playback_Menu_Option',
	pause: 'UI_Player_Pause',
	play: 'UI_Player_Play',
	playback360NotSupported: 'Video_Playback_BrowserNotSupported_360',
	playbackMenu: 'UI_Player_PlaybackMenu',
	playbackNotAvailable: 'Video_Playback_Unavailable',
	playbackNotSupported: 'Video_Playback_BrowserNotSupported',
	playbackSource: 'UI_Player_PlaybackSource',
	playbackSpeed: 'UI_Player_PlaybackSpeed',
	playerSettingsMenu: 'UI_Player_Settings',
	previousChapter: 'UI_Player_PreviousChapter',
	reactions: 'Video_Playback_Reactions',
	reactionsTimeout: 'Video_Playback_Reactions_Timeout',
	reset360: 'UI_Player_Reset_360',
	restart: 'UI_Player_Restart',
	resume: 'UI_Player_Resume',
	revConnectMeshFull: 'UI_Player_RevConnectMeshFull',
	scrubBar: 'UI_Player_TimeScrubBar',
	selectChapter: 'SelectChapter',
	show: 'show',
	showHideReactions: 'Video_Playback_Reactions_ShowHide',
	showChapters: 'ShowChapters',
	sideBySide: 'UI_Player_Layout_SideBySide',
	skipBack10Sec: 'UI_Player_SkipBack10Sec',
	skipForward10Sec: 'UI_Player_SkipForward10Sec',
	speaker20Content80: 'UI_Player_Speaker20Content80',
	speaker50Content50: 'UI_Player_Speaker50Content50',
	speaker80Content20: 'UI_Player_Speaker80Content20',
	speakerBottomRight: 'UI_Player_Layout_SpeakerBR',
	speakerTopRight: 'UI_Player_Layout_SpeakerTR',
	stop: 'UI_Player_Stop',
	subtitles: 'Subtitles',
	trueView: 'UI_Player_VideoVerification_TrueView',
	trueViewVerified: 'UI_Player_VideoVerification_Verified',
	watchAnyway: 'UI_Player_VideoVerification_Watch',
	unmute: 'UI_Player_Unmute',
	volumeLevel: 'UI_Player_VolumeLevel'
};
