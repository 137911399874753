/**
 * <vb-ui-tooltip>
 *
 * Options:
 * tooltip-trigger:
 * show trigger and hide trigger
 * - mouseenter: mouseleave
 * - click: click
 * - outsideClick: outsideClick
 * - focus: blur
 * - none
 *
 * icon-name:
 * select the name of the icon from the glyphicons. defaults to circle_question_mark
 *
 * link-url:
 * the link that will lead user to another url to learn more. if not defined, will be hidden
 *
 * placement:
 * As ngx-bootstrap tooltip placement value
 *
 * text
 * the string that will be used from the translations file
 */

import {
	Component,
	HostListener,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';

import { TooltipDirective } from 'ngx-bootstrap/tooltip';

import styles from './tooltip.module.less';

@Component({
	selector: 'vb-ui-tooltip',
	templateUrl: './VbUiTooltip.Component.html'
})

export class VbUiTooltipComponent implements OnInit {
	@Input() public iconName: string;
	@Input() public linkUrl: string;
	@Input() public placement: string;
	@Input() public tooltipTrigger: string;
	@Input() public text: string;

	public readonly styles = styles;
	@ViewChild('popup')
	public tooltip: TooltipDirective;

	public ngOnInit() {
		this.iconName = this.iconName || 'circle_question_mark';
		this.placement = this.placement || 'bottom';
		this.tooltipTrigger = this.tooltipTrigger || 'mouseenter:click focus:blur';
	}

	@HostListener('mouseleave', ['$event'])
	public hideTooltip(): void {
		if (this.tooltip?.isOpen) {
			this.tooltip.hide();
		}
	}
}
