import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';

import { PushService } from 'rev-shared/push/PushService';
import { lastValueFrom } from 'rxjs';

import { UploadService } from './Upload.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

const IMPORT_RESPONSE_DOWNLOADING_FINISHED: string = 'DownloadingVideoFinished';

@Injectable()
export class ZoomRecordingService {
	constructor(
		private http: HttpClient,
		private PushService: PushService,
		private UploadService: UploadService,
		private UserContext: UserContextService
	) {
	}

	public getRecordings() {
		const email = this.UserContext.getUser().email;
		const url = `/media/videos/zoom/${email}/get-recordings`;
		return lastValueFrom(this.http.post(url, param({
		}),	{
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		}))
			.then(data => {
				data = data || [];
				return (data as any[])
					.map(recording => ({
						...recording,
						durationInMs: recording.durationInSeconds * 1000
					}));
			});
	}

	public importRecording(recordingId: string, title: string, recordingFileUrl: string, fileType: string, vttFileUrl: string, vttLanguageCode: string) {
		//create status entry
		const entry = this.UploadService.createImportStatusEntry(title);

		this.PushService.dispatchCommand('media:AddZoomVideoToAccount', {
			recordingId,
			title,
			recordingFileUrl,
			fileType,
			vttFileUrl,
			vttLanguageCode
		}, [
			'DownloadingVideoFailed',
			'OriginalVideoInstanceStoringFailed',
			'VideoProcessingFailed',
			IMPORT_RESPONSE_DOWNLOADING_FINISHED
		])
			.then(result => {
				// update status
				if (result.eventType === IMPORT_RESPONSE_DOWNLOADING_FINISHED) {
					entry.status.complete = true;
					entry.id = result.message ? result.message.videoId : null;
					entry.uploadDate = result.message ? result.message.when : null;
				}
				else {
					entry.status.error = true;
				}
			}).catch(() => {
				entry.status.error = true;
			});
	}
}
