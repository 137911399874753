import { Directive } from '@angular/core';

import styles from './VbBtn.Directive.module.less';

@Directive({
	selector: '[vbBtnWhite]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbBtnWhiteDirective {
	public readonly hostClass = `btn ${styles.vbBtn} ${styles.btnWhite}`;
}
