<form [ngClass]="{
	'theme-primary': params.themed
}">
	<div class="modal-header" [ngClass]="{
			'theme-accent-bg theme-primary-font-border-fade-50': params.themed
		}">
		<span *ngIf="params.headerIconClass" [ngClass]="params.headerIconClass" class="glyphicons" [attr.icon-type]="params.headerIconType">
		</span>
		<h4 id="modal-title" [ngClass]="{ 'theme-accent-txt': params.themed }">
			{{params.title}}&nbsp;
		</h4>
		<button type="button" class="close float-right btn btn-transparent" [class.theme-accent-txt]="params.themed" *ngIf="params.cancelText" [ngClass]="params.themed ? styles.themedCloseBtn : ''" (click)="hide()" [attr.aria-label]="'Close' | translate">

			<span class="glyphicons remove_2"></span>
		</button>
	</div>
	<div id="modal-body" class="modal-body" [ngClass]="params.messageBodyClass">
		<div *ngIf="params.htmlMessage" [innerHTML]="params.htmlMessage">
		</div>
		<div *ngIf="!params.htmlMessage">
			{{params.message}}
		</div>

		<vb-ui-checkbox [ngClass]="styles.rememberCheckbox" *ngIf="params.rememberText" [(ngModel)]="remember">
			{{ params.rememberText }}
		</vb-ui-checkbox>
	</div>

	<div class="modal-footer" *ngIf="!params.themed">
		<button [hidden]="!params.cancelText" (click)="hide()" vbBtnWhite>
			{{params.cancelText}}
		</button>

		<button [hidden]="!params.secondaryActionText" (click)="onActionInternal(ConfirmationDialogAction.SECONDARY)" vbBtnWhite>
			{{params.secondaryActionText}}
		</button>

		<button type="submit" [hidden]="!params.actionText" (click)="onActionInternal(ConfirmationDialogAction.PRIMARY)" vbAutoFocus vbBtnPrimary>
			{{params.actionText}}
		</button>
	</div>

	<div class="modal-footer" *ngIf="params.themed">
		<button [hidden]="!params.cancelText" (click)="hide()" vbBtnSecondary>
			{{params.cancelText}}
		</button>

		<button [hidden]="!params.secondaryActionText" (click)="onActionInternal(ConfirmationDialogAction.SECONDARY)" vbBtnSecondary>
			{{params.secondaryActionText}}
		</button>

		<button type="submit" [hidden]="!params.actionText" (click)="onActionInternal(ConfirmationDialogAction.PRIMARY)" vbAutoFocus vbBtnPrimary>
			{{params.actionText}}
		</button>
	</div>
</form>
