import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { IVideoVerificationConfig } from '@vbrick/vbrick-player/app/player/IVideoVerificationConfig';
import { MediaFeaturesService } from '../media/MediaFeatures.Service';

@Injectable({
	providedIn: 'root'
})
export class VideoVerificationService {
	constructor(
		private http: HttpClient,
		private MediaFeaturesService: MediaFeaturesService
	) { }

	public getVideoVerificationConfig(videoInstanceId: string, videoId: string, fingerprint: string, accountName: string): Promise<IVideoVerificationConfig> {
		return lastValueFrom(this.http.post(`/media/videos/${videoId}/verify`, {
			sourceId: videoInstanceId,
			fingerprint
		}))
			.then((response: any) => {
				return {
					isEnabled: this.MediaFeaturesService.accountFeatures.sourceVerificationEnabled,
					isVerified: response,
					accountName
				};
			});
	}
}
