import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { setOpacity, findOverlayColor, lightenHexColor, darkenHexColor } from './ColorUtility';
import { ThemeService } from './Theme.Service';

@Component({
	selector: 'vb-theme-styles',
	template: `<ng-template [vbCssRules]="themeStyles"></ng-template>`
})
export class ThemeStylesComponent implements OnInit, OnDestroy {
	public themeStyles: IRules;
	public sub: Subscription;

	constructor(
		public readonly ThemeService: ThemeService
	) {
	}

	public ngOnInit(): void {
		this.sub = this.ThemeService.brandingSettings$.subscribe(settings => {
			if(settings?.isLoaded) {
				this.updateStyles(settings);
			}
		});
	}

	public ngOnDestroy(): void {
		this.sub?.unsubscribe();
	}

	public updateStyles(brandingSettings: BrandingSettings): void {
		const theme = brandingSettings.themeSettings;
		const header = brandingSettings.headerSettings;

		this.themeStyles = {
			':root': {
				'--theme-primary': theme.primaryColor,
				'--theme-primary-darken-20': darkenHexColor(theme.primaryColor, 20),
				'--theme-primary-darken-30': darkenHexColor(theme.primaryColor, 30),
				'--theme-primary-lighten-10': lightenHexColor(theme.primaryColor, 10),
				'--theme-primary-fade-90': setOpacity(theme.primaryColor, 0.9),
				'--theme-primary-txt': theme.primaryFontColor,
				'--theme-primary-txt-fade-90': setOpacity(theme.primaryFontColor, 0.9),
				'--theme-primary-txt-fade-70': setOpacity(theme.primaryFontColor, 0.7),
				'--theme-primary-txt-fade-50': setOpacity(theme.primaryFontColor, 0.5),
				'--theme-primary-overlay': findOverlayColor(theme.primaryColor),
				'--theme-accent': theme.accentColor,
				'--theme-accent-darken-20': darkenHexColor(theme.accentColor, 20),
				'--theme-accent-fade-15': setOpacity(theme.accentColor, 0.15),
				'--theme-accent-fade-50': setOpacity(theme.accentColor, 0.5),
				'--theme-accent-txt': theme.accentFontColor,
				'--theme-accent-txt-fade-50': setOpacity(theme.accentFontColor, 0.5),
				'--theme-accent-overlay': findOverlayColor(theme.accentColor),
				'--theme-header': header.backgroundColor,
				'--theme-header-overlay': findOverlayColor(header.backgroundColor),
				'--theme-header-txt': header.fontColor,
				'--theme-header-txt-fade-50': setOpacity(header.fontColor, 0.5),
				'--theme-header-txt-fade-75': setOpacity(header.fontColor, 0.75)
			},
			'.theme-primary, .theme-primary-bg': {
				'background-color':  theme.primaryColor
			},
			'.theme-primary, .theme-primary-txt': {
				color: theme.primaryFontColor
			},
			'.theme-primary-txt-fade-50': {
				color: setOpacity(theme.primaryFontColor, 0.5)
			},
			'.theme-primary-important': {
				'background-color': `${theme.primaryColor} !important`,
				color: `${theme.primaryFontColor} !important`
			},
			'.theme-primary-txt-important': {
				color: `${theme.primaryFontColor} !important`
			},
			'.theme-primary-border': {
				'border-color': theme.primaryFontColor
			},
			'.theme-accent-bg': {
				'background-color': `${theme.accentColor} !important`
			},
			'.theme-accent-txt': {
				color: `${theme.accentFontColor} !important`
			},
			[`  .theme-accent,
				.theme-accent-btn:hover,
				.theme-hover-zone:hover .theme-accent-btn,
				.theme-accent-btn.active`]: {
				'background-color': `${theme.accentColor} !important`,
				'color': `${theme.accentFontColor} !important`
			},
			'.theme-accent-low': {
				'background-color': theme.accentColor,
				'color': theme.accentFontColor
			},
			'.theme-accent-inverted': {
				'background-color': `${theme.accentFontColor} !important`,
				'color': `${theme.accentColor} !important`
			},
			[
			`.theme-accent-border,
				.theme-accent-border-focus:focus,
				.theme-accent-border-hover:hover`
			]: {
				'border-color': theme.accentColor
			},
			[
			`.theme-accent-border-important`
			]: {
				'border-color': `${theme.accentColor} !important`
			},
			'.theme-accent-font-border': {
				'border-color': theme.accentFontColor
			},
			[`	.theme-accent-link,
				.theme-accent-link:hover,
				.theme-accent-link:focus,
				.theme-accent-link:visited,
				.theme-accent-icon:before`]: {
				color: `${theme.accentColor} !important`
			},
			[`	.theme-primary-font-link,
				.theme-primary-font-link:hover,
				.theme-primary-font-link:focus,
				.theme-primary-font-link:visited,
				.theme-primary-font-icon:before`]: {
				color: `${theme.primaryFontColor} !important`
			},
			'@font-face': {
				'font-family': 'theme-font',
				'src': `url("${theme.fontUri}") format('woff')`
			},
			'.theme-font': {
				'font-family': 'theme-font, \'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\';'
			},
			'.theme-header, .theme-header-bg': {
				'background-color': `${header.backgroundColor} !important`
			},
			'.theme-header, .theme-header-txt': {
				color: `${header.fontColor} !important`
			},
			'.theme-header-font-border-color': {
				'border-color': `${header.fontColor} !important`
			},
			'.theme-primary-font-border-fade-50': {
				'border-color': setOpacity(theme.primaryFontColor, .5)
			},
			'.theme-primary-font-border-fade-30': {
				'border-color':  `${setOpacity(theme.primaryFontColor, .3)} !important`
			},
		};
	}
}
