import { NgZone } from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { VideogularComponent } from 'vbrick-player-src/videogular/Videogular.Component';
import { IVideoOverlay } from 'vbrick-player-src/videoOverlay/IVideoOverlay';

import { BaseWebcast } from 'rev-portal/scheduledEvents/webcast/BaseWebcast';
import { DeviceType } from 'rev-shared/media/DeviceContract';
import { EventAccessControl, AttendeeJoinMethod } from 'rev-portal/scheduledEvents/EventAccessControl';
import { IEmbeddedContent } from 'rev-portal/scheduledEvents/common/EmbeddedContentTypes';
import { IPreProduction } from 'rev-portal/scheduledEvents/webcast/Webcast.Contract';
import { IBannerDetails } from 'rev-portal/scheduledEvents/common/BannerTypes';
import { IRtmpSettings } from 'rev-portal/scheduledEvents/editWebcast/IRtmpSettings';
import { IWebcastBrandingSettings } from 'rev-portal/scheduledEvents/webcast/WebcastBranding';
import { Polls } from 'rev-portal/scheduledEvents/polls/Polls';
import { PresentationModel } from 'rev-portal/scheduledEvents/presentations/PresentationModel';
import { WebcastPresentationService } from 'rev-portal/scheduledEvents/presentations/WebcastPresentation.Service';
import { WebcastType } from 'rev-portal/scheduledEvents/webcast/WebcastType';
import { WebcastVideoSource } from 'rev-portal/scheduledEvents/webcast/WebcastVideoSource';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { ILiveSubtitles } from 'rev-shared/ui/liveSubtitles/ILiveSubtitles';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { IExternalPresenter, IProducerOptions } from 'rev-shared/webcast/webcastView/producer/Contract';
import { MinuteMs } from 'rev-shared/date/Time.Constant';
import { PollsModelService } from 'rev-portal/scheduledEvents/polls/PollsModel.Service';
import { PresentationFileStatus } from 'rev-portal/scheduledEvents/presentations/PresentationFileStatus';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { RecordingPolicy } from 'rev-shared/media/RecordingPolicy';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { VideoOverlay, VIDEO_OVERLAY_TYPE } from 'rev-shared/videoPlayer/VideoOverlay';
import { ViewerIdPolicy } from 'rev-shared/viewerId/ViewerIdContract';
import { WebRtcListenerConnectionService } from 'rev-shared/webrtc/WebRtcListenerConnection.Service';
import { constructViewerIdOverlay } from 'rev-shared/videoPlayer/VideoOverlayUtility';
import { isPastEndDate, isTimeToStart, isTimeToStartPreProduction } from 'rev-shared/webcast/WebcastTimeCalculation';
import { noop } from 'rev-shared/util';
import { readProducerOptions } from 'rev-shared/webcast/webcastView/producer/Producer.Service';
import { AnalyticsViewContext } from 'rev-shared/analytics/Constant';

import { BroadcastStatus } from './BroadcastStatus';
import { RecordingStatus } from './RecordingStatus';
import { RunItem, WebcastRunType, WebcastRunStatus } from './RunItem';
import { VcStatus } from './VcStatus';
import { WebcastLayout } from './WebcastLayout';
import { WebcastService } from '../Webcast.Service';
import { WebcastStatus } from './WebcastStatus';
import { WebcastUser } from './WebcastUser';
import { ReactionCfg } from '@vbrick/vbrick-player/app/player/reactions/ReactionCfg';
import { EmojiChar } from 'rev-shared/htmlEditor/EmojiData';

export interface IVodInfo {
	durationMs: number;
	id: string;
	ownerFirstName: string;
	ownerLastName: string;
	sourceType: string;
	thumbnailSheets: any;
	thumbnailUri: string;
	title: string;
	isDualPlayback?: boolean;
	whenUploaded: string;
}

export interface IWebcastModelRequiredServices {
	DateParsers: DateParsersService;
	PollsModelService: PollsModelService;
	PushBus: PushBus;
	UserContext: UserContextService;
	UserLocalIPService: UserLocalIPService;
	WebcastPresentationService: WebcastPresentationService;
	WebcastService: WebcastService;
	ListenerConnection: WebRtcListenerConnectionService;
	zone: NgZone;
}

export interface IProducerBgImage {
	id: string;
	small?: string;
	original?: string;
	extraSmall?: string;
}

export interface ICustomConsentDetails {
	isCustomConsentEnabled: boolean;
	consentVerbiage: string;
	customConsentVerbiageUpdates: ICustomConsentVerbiageUpdate[]
}

export interface ICustomConsentVerbiageUpdate{
	submissionTimestamp: Date;
	name: string;
	consentVerbiage: string;
	username: string;
}

export interface IWebcastReactionsSettings {
	enabled: boolean;
	emojis: EmojiChar[];
}

export class WebcastModel extends BaseWebcast {
	private readonly DateParsers: DateParsersService;
	private readonly PollsModelService: PollsModelService;
	private readonly PushBus: PushBus;
	private readonly UserContext: UserContextService;
	private readonly UserLocalIPService: UserLocalIPService;
	private readonly WebcastPresentationService: WebcastPresentationService;
	private readonly WebcastService: WebcastService;
	private readonly zone: NgZone;

	private readonly webcastSubject$ = new BehaviorSubject<WebcastModel>(this);

	private features: IMediaFeatures;

	public readonly webcast$ = this.webcastSubject$.asObservable()
		.pipe(
			tap(() => this.zone.run(noop))
		);

	public readonly currentUser: WebcastUser;
	public readonly presentation: PresentationModel;
	public readonly recording: RecordingStatus;
	public readonly broadcast: BroadcastStatus;
	public readonly vcStatus: VcStatus;
	public readonly webcastStatus: WebcastStatus;
	public readonly layout: WebcastLayout;
	public readonly video: { vgAPI: VideogularComponent } = { vgAPI: null };

	public readonly environmentId: string;
	public readonly mediaCachingEnabled: boolean;
	public videoHeartbeatInterval: number; //s
	public recordingDeviceId: string; //consider move to recordingStatus
	public readonly recordingVideoStatus: VideoStatus;
	public isRecording: boolean;
	public disableAutoRecording: boolean;
	public startingVideoSource: boolean;
	public waitingForVideoSource: boolean;
	public playbacks: any[];
	public isUpdatingPlaybacks: boolean;
	public backgroundImages: any[];
	public recordingIsReconnecting: boolean;
	public isPresenterReady: boolean;

	public attendeeQuestionsSubscription: Subscription;

	public runItems: RunItem[];
	public preProductionRun: RunItem;
	public productionRun: RunItem;
	public currentRun: RunItem;
	public preProduction: IPreProduction;
	public polls: Polls;
	public linkedVideoId: string;
	public linkedVideo?: { id: string; title: string; isActive: boolean };

	public liveSubtitles: ILiveSubtitles;
	public isPresentationProfileDeviceInactive: boolean;
	public isTrustedPublicWebcast: boolean;
	public eventHostName: { firstName?: string, lastName: string };
	public eventHostFullName: string;
	public hideShareUrl: boolean;

	public rtmpSettings: IRtmpSettings;
	public webRtcListenerUrl: string;

	public enableCustomBranding: boolean = false;
	public webcastBrandingSettings: IWebcastBrandingSettings = {};
	public logoUri: string;

	public emailToPreRegistrants: boolean;

	public webcastType: WebcastType;
	public presenterId: string;
	public presenterIds: string[];
	public activePresenters: any[] = [];
	public externalPresenters: IExternalPresenter[];
	public dualPlaybackSource: boolean;
	public vodInfo?: IVodInfo;
	public broadcastTime?: string;
	public producerOptions?: IProducerOptions;

	public analyticsViewContext = AnalyticsViewContext.Unknown;
	public viewerIdEnabled?: boolean;
	public viewerIdWatermarkText: string;
	public videoOverlays: VideoOverlay[];

	public embeddedContent?: IEmbeddedContent;
	public bannerDetails?: IBannerDetails;
	public producerBgImages: IProducerBgImage[];

	public customConsentDetails: ICustomConsentDetails;
	public reactionsSettings: IWebcastReactionsSettings;

	constructor(
		webcastData: any,
		webcastUserData: any,
		requiredServices: IWebcastModelRequiredServices
	) {
		super();
		Object.assign(this, webcastData, requiredServices);

		this.polls = this.PollsModelService.initPolls(this);
		this.presentation = new PresentationModel(this, webcastData.presentationFile, {
			DateParsers: this.DateParsers,
			PushBus: this.PushBus,
			WebcastPresentationService: this.WebcastPresentationService
		},
		this.zone);
		const user = this.UserContext.getUser();
		this.currentUser = new WebcastUser(this, {
			id: user.id,
			...webcastUserData
		});
		this.recording = new RecordingStatus(this, this.PushBus, this.WebcastService);
		this.broadcast = new BroadcastStatus(this, this.PushBus, this.WebcastService, requiredServices.ListenerConnection);
		this.vcStatus = new VcStatus(this);
		this.webcastStatus = new WebcastStatus(this);
		this.layout = new WebcastLayout(this);

		this.producerOptions = this.isProducerEvent ? readProducerOptions(this.producerOptions) : null;

		if(this.runItems) {
			this.runItems = this.runItems.map(i => new RunItem(i));
			this.initRunItems();
		}
		this.eventHostFullName = `${this.eventHostName?.firstName ?? ''} ${this.eventHostName?.lastName ?? ''}`;
		this.assignDualVideoSource();
	}

	public update(data?: Partial<WebcastModel>) {
		if(data) {
			Object.assign(this, data);
		}

		this.assignDualVideoSource();
		this.webcastSubject$.next(this);
	}

	public addVideoOverlay(overlay: VideoOverlay): void {
		if (!overlay) {
			return;
		}
		this.videoOverlays = this.videoOverlays?.length ? [...this.videoOverlays, overlay] : [overlay];
		this.update();
	}

	public removeVideoOverlay(type: VIDEO_OVERLAY_TYPE): void {
		if (this.videoOverlays?.some(v => v.type === type)) {
			this.videoOverlays = this.videoOverlays?.filter(v => v.type !== type);
		}
		this.update();
	}

	public get canDownloadPresentationFile(): boolean {
		//TODO: Move to presentation model object
		return this.presentationFileDownloadAllowed &&
			this.presentation.status === PresentationFileStatus.Complete &&
			!!this.presentation.downloadUrl;
	}

	public get isAutomated(): boolean {
		if(this.currentRun && this.currentRun.isPreProduction) {
			return false;
		}
		return this.automatedWebcast;
	}

	public get isDeviceEncoder(): boolean {
		return this.deviceType === DeviceType.Encoder;
	}

	public isTimeToStart(): boolean {
		return isTimeToStart(this.startDate, this.endDate, this.lobbyTimeMinutes);
	}

	public isPastEndDate(): boolean {
		return isPastEndDate(this.endDate);
	}

	public isTimeToStartPreProduction(): boolean {
		if(this.preProduction) {
			return isTimeToStartPreProduction(this.preProduction.durationMs, this.startDate);
		}
	}

	public isPublicAnonymousJoin(): boolean {
		return this.accessControl === EventAccessControl.Public
			&& this.attendeeJoinMethod === AttendeeJoinMethod.Anonymous;
	}

	public get isReadyToStart(): boolean {
		const preProdRun = this.preProductionRun;
		if(this.currentUser.isEventAdmin &&
			preProdRun && preProdRun.isStarted) {
			return false;
		}

		const run = this.productionRun;
		return run && (
			run.isStarted ||
			this.isTimeToStart()
		) &&
			!this.webcastStatus.isCancelled &&
			!this.webcastStatus.isFailed &&
			!(this.isVodSource && this.webcastStatus.isCompleted);
	}

	public get isReadyToStartPreProduction(): boolean {
		const run = this.preProductionRun;
		return run && (
			run.isStarted ||
			this.isTimeToStartPreProduction()
		) &&
			this.productionRun.isScheduled &&
			!this.webcastStatus.isCancelled &&
			!this.webcastStatus.isFailed;
	}

	public get preProdStartDate(): Date {
		return this.preProduction && new Date(this.startDate.getTime() - this.preProduction.durationMs);
	}

	public get lobbyStartDate(): Date {
		return new Date(this.startDate.getTime() - this.lobbyTimeMinutes * MinuteMs);
	}

	public get isUniversalEcdnEvent() {
		return this.videoSource === WebcastVideoSource.REV_CONNECT_STREAM || this.videoSource === WebcastVideoSource.EXTERNAL;
	}

	public get isVideoConferencingType(): boolean {
		return !!this.vcSipAddress ||
			this.isWebexLiveStreamType ||
			this.isRtmpStreamType ||
			this.isWebrtcSinglePresenter ||
			this.isProducerEvent ||
			this.videoSource === WebcastVideoSource.MSTEAMS;
	}

	public get enableLiveStreamStatusDisplay(): boolean {
		return this.isVideoConferencingType || this.isEnrichedPresentationProfile || this.isVodSource;
	}

	public get isPresentationProfileType(): boolean {
		return this.videoSource === WebcastVideoSource.PRESENTATION;

	}
	public get isWebexLiveStreamType(): boolean {
		return this.videoSource === WebcastVideoSource.WEBEX_LIVE_STREAM;
	}

	public get isRtmpStreamType(): boolean {
		return this.videoSource === WebcastVideoSource.RTMP;
	}

	public get isVodSource(): boolean {
		return this.videoSource === WebcastVideoSource.VOD;
	}

	public get isWebrtcSinglePresenter(): boolean {
		return this.videoSource === WebcastVideoSource.WEBRTC_SINGLE_PRESENTER;
	}

	public get isProducerEvent(): boolean {
		return this.videoSource === WebcastVideoSource.PRODUCER;
	}

	public get adminEnrichedPresentationProfile(): boolean {
		return this.currentUser.isEventAdmin
			&& this.isEnrichedPresentationProfile;
	}

	public get isEnrichedPresentationProfile(): boolean {
		return this.isPresentationProfileType
			&& this.liveSubtitles?.isLiveSubtitlesEnabled;
	}

	public get isVCI(): boolean {
		return this.vcSipAddress ||
			this.vcMicrosoftTeamsMeetingUrl ||
			this.isEnrichedPresentationProfile ||
			this.isWebrtcSinglePresenter ||
			this.isProducerEvent ||
			this.isRtmpStreamType as any;
	}

	public get webexEventsWebcastType(): boolean {
		return this.webcastType === WebcastType.WebexEvents;
	}

	public get isDtmfEnabledSource(): boolean {
		return this.videoSource === WebcastVideoSource.SIP_ADDRESS ||
			this.videoSource === WebcastVideoSource.PEXIP ||
			this.videoSource === WebcastVideoSource.ZOOM;
	}

	public assignCurrentRun(isPreProd: boolean): void {
		this.currentRun = isPreProd ? this.preProductionRun :
			this.productionRun;
	}

	public getRunItem(runNumber: number): RunItem {
		return this.runItems.find(r => r.runNumber === runNumber);
	}

	public addRunItem(run: any): void {
		this.runItems.push(new RunItem(run));
		this.initRunItems();
	}

	public removePreProdRuns(): void {
		this.runItems = this.runItems.filter(({ runType }) => runType !== WebcastRunType.PreProduction);
		this.initRunItems();
	}

	public initFeatures(features: IMediaFeatures): void {
		this.features = features;
	}

	public isPollsEnabled(): boolean {
		return this.webexEventsWebcastType ? this.pollsEnabled
			: !!this.features?.webcastEngagementSettings?.allowPolls && this.pollsEnabled;
	}

	public isChatEnabled(): boolean {
		return this.webexEventsWebcastType ? this.chatEnabled
			: !!this.features?.webcastEngagementSettings?.allowChat && this.chatEnabled;
	}

	public isEmbeddedContentEnabled(): boolean {
		return this.embeddedContent?.isEnabled &&
			this.features?.webcastEmbedContentSettings?.isAvailable &&
			this.features?.webcastEmbedContentSettings?.isEnabled;
	}

	public isBannerEnabled(): boolean {
		return this.bannerDetails?.isEnabled && this.features?.webcastEngagementSettings?.allowSendLinkToAllAttendees;
	}

	public isQuestionAndAnswerEnabled(): boolean {
		return this.webexEventsWebcastType ? this.questionAndAnswerEnabled
			: !!this.features?.webcastEngagementSettings?.allowQuestionAndAnswer && this.questionAndAnswerEnabled;
	}

	public isRecordingAllowed(): boolean {
		return this.features &&
			this.features.recordingPolicy !== RecordingPolicy.DISABLE &&
			!this.isVodSource;
	}

	public isStartOrStopRecordingAllowed(): boolean {
		return this.features?.recordingPolicy === RecordingPolicy.ALLOW;
	}

	public isRecordingMandatory(): boolean {
		return this.features.recordingPolicy === RecordingPolicy.REQUIRE;
	}

	public constructViewerIdOverlay(): IVideoOverlay {
		return constructViewerIdOverlay(this.features?.viewerIdSettings, this.UserContext, this.UserLocalIPService, this.viewerIdEnabled, this.features.viewerIdSettings.viewerIdPolicy === ViewerIdPolicy.ALLOW ? this.viewerIdWatermarkText : '');
	}

	private initRunItems(): void {
		this.preProductionRun = this.runItems.find(i =>
			i.isPreProduction &&
			i.status !== WebcastRunStatus.Ended);

		this.productionRun = this.runItems.find(i => i.isMainEvent);
	}

	private assignDualVideoSource(): void {
		this.dualPlaybackSource = !!this.vcSipAddress
			|| !!this.vcMicrosoftTeamsMeetingUrl
			|| this.vodInfo?.isDualPlayback
			|| this.isWebrtcSinglePresenter
			|| this.isProducerEvent;
	}

	public getReactionCfg(): Observable<ReactionCfg> {
		return new Observable<ReactionCfg>(observer => {
			if(!this.features.webcastEngagementSettings.allowEmojiReactions) {
				observer.next(null);
				return;
			}
			let reactionCfg = {
				...this.reactionsSettings,
				delaySeconds: this.features.webcastEngagementSettings.reactionIntervalSeconds,
				reactions$: this.WebcastService.getUserReactions(this.id)
			};

			observer.next(reactionCfg);

			return this.webcast$.subscribe(webcast => {
				let next = null;
				if(webcast.webcastStatus.isBroadcasting) {
					reactionCfg = { ...reactionCfg, ...webcast.reactionsSettings };
					next = reactionCfg;
				}

				observer.next(next);
			});
		});
	}
}
