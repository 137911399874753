export type EmojiChar = { name: string; char: string; };
export type EmojiGroup = { name: string; emojis: EmojiChar[] };


let resolve;
let loaded = false;
export const emojiGroups$ = new Promise<EmojiGroup[]>(r => resolve = r);
export const DefaultEmojis = [
	{ char: '😀', name: 'Smile' },
	{ char: '👎', name: 'Thumbs Down' },
	{ char: '👏', name: 'Clapping Hands' },
	{ char: '❤️', name: 'Heart' },
	{ char: '👍', name: 'Thumbs Up' }
];
const defaultGroup = '😃';


//loads emoji characters and names from the ckeditor emoji plugin at runtime.
//Called automatically from ckeditor the first time it loads.
export function loadEmoji(emojiPlugin: any): void {
	if(loaded) {
		return;
	}

	const result = [];
	const groups: Map<string, Set<string>> = emojiPlugin._groups;
	const chars: Map<string, string> = emojiPlugin._characters;

	for(const [group, names] of groups) {
		const emojis: EmojiChar[] = [];
		for(const name of names) {
			const char = chars.get(name);
			if(char) {
				emojis.push({ name, char });
			}
		}
		const val = { name: group, emojis };

		if(group.includes(defaultGroup)) {
			result.unshift(val);
		}
		else {
			result.push(val);
		}
	}

	loaded = true;
	resolve(result);
}
