
export interface IImageDimensions {
	height: number;
	width: number;
}

/**
	Fetch the image URI and cache it in the browser.
	Returns a promise that is resolved when the image has loaded.
**/
export function prefetchImage(uri: string): Promise<void> {
	return new Promise((resolve, reject) => {
		if(!uri) {
			return reject('ImagePrefetcher: URI is required');
		}

		const image = new Image();
		image.addEventListener('load', () => resolve());
		image.addEventListener('error', reject);
		image.src = uri;
	});
}

export function getDimensions(uri: string): Promise<IImageDimensions> {
	return new Promise((resolve, reject) => {
		if(!uri) {
			return reject('URI is required');
		}

		const img = new Image();

		img.addEventListener('load', () => {
			resolve({
				height: img.height,
				width: img.width
			});
			img.remove();
		});
		img.addEventListener('error', reject);
		img.src = uri;
	});
}

export function getDataUri(file: File): Promise<string> {

	if(!window.FileReader) {
		return Promise.reject();
	}

	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = (e: ProgressEvent<FileReader>) => Promise.resolve(e.target.result)
			.then(result => file.type.match(/^image/) ?
				fixOrientation(result) : result
			)
			.then(fixed => resolve(fixed as string));

		reader.readAsDataURL(file);

	});
}

function fixOrientation(data: any): Promise<string> {
	return import(/* webpackChunkName: "fixOrientation" */ 'fix-orientation/index')
		.then(({ default: fixOrientation }) => new Promise((resolve, reject) => {
			fixOrientation(
				data,
				{ image: false },
				fixed => window.setTimeout(() => resolve(fixed), 0)
			);
		}));
}

export function createImageFileFromDataUri(uri: string): Blob {
	let byteString;

	const inputMIME = uri.split(',')[0].split(':')[1].split(';')[0];
	if (uri.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(uri.split(',')[1]);
	} else {
		byteString = unescape(uri.split(',')[1]);
	}

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: inputMIME });
}

