import { NgModule } from '@angular/core';

import { FileUploadAngularDirective } from './FileUpload.AngularDirective';

import 'jquery-ui/ui/widget';
import 'jquery-ui/themes/base/core.css';
import 'jquery-file-upload'; // way behind. version not published anymore. 5.26 but now on 9.12.5 (though not kept up publishing to npm for some reason). requires jquery-ui widget
import 'jquery-file-upload/jquery.iframe-transport';

const exports = [FileUploadAngularDirective];
@NgModule({
	declarations: exports,
	exports
})
export class FileUploadAngularModule {}
