<div dropdown container="body" [ngClass]="styles.menuWrapper" vbDropdown>

	<button type="button" dropdownToggle vbBtnWhite class="dropdown-toggle" [ngClass]="customIconClass ? 'btn-transparent' : styles.actionButton" *ngIf="isMenuPopulated">
		<span *ngIf="actionBtnInfo?.label else ellipsis">
			{{ actionBtnInfo.label }}
		</span>

		<ng-template #ellipsis>
			<ng-container *ngIf="customIconClass; else classicEllipsis">
				<span [ngClass]="customIconClass"></span>
			</ng-container>

			<ng-template #classicEllipsis>
				<span class="more-icon" [ngClass]="styles.moreIcon">
					<span class="circle"></span>
					<span class="circle"></span>
					<span class="circle"></span>
				</span>
			</ng-template>
		</ng-template>
	</button>

	<div *dropdownMenu role="menu" class="dropdown-menu" [ngClass]="[
			styles.menuItemWrapper,
			themed ? styles.themedWrapper : '',
			menuWrapperCssClass || ''
		]">
		<ng-container *ngFor="let menuItem of menuItemsInfo">
			<button *ngIf="menuItem.isVisible?.(data) || !menuItem.isVisible" role="menuitem" class="btn btn-transparent dropdown-item" [ngClass]="styles.menuItem" [title]="menuItem.label || (menuItem.labelKey | translate)" (click)="onClick(menuItem)">
				{{ menuItem.label || (menuItem.labelKey | translate) }}
			</button>
		</ng-container>
	</div>

</div>
