import { Directive, HostBinding, Input } from '@angular/core';

import styles from './VbBtn.Directive.module.less';

@Directive({
	selector: '[vbBtnSecondary]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbBtnSecondaryDirective {
	@HostBinding('class.btnSecondaryInactive')
	@Input() public inactive: boolean; //inactive is not disabled.
	@Input() public isWhite: boolean = false;

	public hostClass: string;

	public ngOnInit(): void {
		this.hostClass = `btn ${styles.vbBtn} ${this.isWhite ? styles.btnWhite : styles.btnSecondary}`;
	}
}
