
<div class="btn-group" dropdown [insideClick]="true" *ngIf="dropdown; else dateRangeContent">
	<button type="button" class="dropdown-toggle" dropdownToggle vbBtnWhite>
		<span class="glyphicons calendar" [ngClass]="styles.calendarIcon">
		</span>
		{{ getLabel() }}
	</button>
	<div *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
		<ng-content *ngTemplateOutlet="dateRangeContent"></ng-content>
	</div>
</div>

<ng-template #dateRangeContent>
	<div [ngClass]="styles.content">
		<section [ngClass]="styles.sidebar">
			<a *ngFor="let range of ranges" [ngClass]="getLinkClass(range)" (click)="onRangeLinkClicked(range)">
				{{ range.label }}
			</a>
		</section>

		<section class="vb-ui-date-picker">
			<date-picker-styles></date-picker-styles>
			<bs-daterangepicker-inline [bsValue]="value?.range" (bsValueChange)="onDateRangeChanged($event)" [bsConfig]="bsConfig" [minDate]="minDate" [maxDate]="maxDate">
			</bs-daterangepicker-inline>
		</section>
	</div>
</ng-template>
