import {
	Component,
	Input
} from '@angular/core';
import { StateService } from '@uirouter/angular';

import { BrandingSettings } from './BrandingSettings.Contract';
import { ThemeService } from './Theme.Service';
import { BrandingService } from './Branding.Service';

import { AppBootstrapService } from 'rev-shared/bootstrap/AppBootstrap.Service';

@Component({
	selector: 'branding-preview-button',
	template: `
		<button type="button" (click)="setPreviewMode()" vbBtnPrimary>
			{{label}}
		</button>
	`
})
export class BrandingPreviewButtonComponent {
	@Input() public label: string;
	@Input() private brandingSettings: BrandingSettings;

	constructor(
		private $state: StateService,
		private AppBootstrapService: AppBootstrapService,
		private ThemeService: ThemeService,
		private BrandingService: BrandingService
	) {}

	public setPreviewMode(): void {
		const { state, params } = this.AppBootstrapService.getAppLandingState();
		this.$state.go(state, params)
			.then(() => {
				const settings = this.BrandingService.readBrandingSettings(this.brandingSettings);
				this.ThemeService.setPreviewMode(settings);
			});
	}
}
