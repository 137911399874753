import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/slider.css';

import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';

import './VbrickPlayerWmv.less';

interface IDocumentModeIE extends Document {
	documentMode?: number;
}

@Component({
	selector: 'vbrick-player-wmv',
	host: {
		class: 'vb-player vb-player-ie'
	},
	templateUrl: './VbrickPlayerWmv.Component.html'
})
export class VBrickPlayerWmvComponent implements OnDestroy, OnChanges, OnInit {
	@Input() public autoPlay: boolean;
	@Input() public canShowThumbnail: boolean;
	@Input() public live: boolean;
	@Input() public playbackOptions: any[];
	@Input() public thumbnailUri: string;
	@Input() public videoUrl: string;

	@Output() public onComplete: EventEmitter<{ duration: number }> = new EventEmitter();
	@Output() public onPause: EventEmitter<{ timestamp: number }> = new EventEmitter();
	@Output() public onPlay: EventEmitter<any> = new EventEmitter();
	@Output() public onPlaybackPositionUpdated: EventEmitter<{ time: number }> = new EventEmitter();
	@Output() public onPlayerReady: EventEmitter<{ vgAPI: any }> = new EventEmitter();
	@Output() public onStop: EventEmitter<any> = new EventEmitter();
	@Output() public onFullscreenToggled: EventEmitter<{ isFullscreen: boolean }> = new EventEmitter();

	private static readonly playerName: string = 'VbrickWmvPlayer';

	public controls: any;
	private currentPositionInterval: number;
	private discardPlayerEvent: boolean;
	private escapeEventBinding: (event) => void;
	private fullScreen: boolean;
	public hasPlugin: boolean;
	private id: string;
	public isNativeFullScreenSupport: boolean;
	public isPlaybackOptionsMenuOpen: boolean;
	public noVideoOverlay: boolean;
	private pauseAfterRestore: boolean;
	private player: any;
	private playState: number;
	private restorePlaybackPosition: number;
	public showControls: boolean;
	private skipEscapeEventBinding: (event) => void;
	public stream: any;

	@ViewChild('playerPlaceholder', { static: true }) private playerPlaceholder: ElementRef<HTMLElement>;
	@ViewChild('progressCtrl', { static: true }) private progressCtrl: ElementRef<HTMLElement>;
	@ViewChild('volumeCtrl', { static: true }) private volumeCtrl: ElementRef<HTMLElement>;

	constructor(
		private hostElement: ElementRef<HTMLElement>
	) {}

	public ngOnInit(): void {
		const doc: IDocumentModeIE = document as IDocumentModeIE;

		this.controls = {
			playerContainerEl: this.playerPlaceholder.nativeElement,
			volumeCtrl: $(this.volumeCtrl.nativeElement),
			progressCtrl: $(this.progressCtrl.nativeElement),
			isClosedCaptionOn: false,
			removeCurrentPlayer: () => this.removeCurrentPlayer(),
			appendVideoPlayerEl: url => this.appendVideoPlayerEl(url),
			onVolumeSliderStop: (event, ui) => this.onVolumeSliderStop(event, ui),
			onProgressSliderStop: (event, ui) => this.onProgressSliderStop(event, ui),
			onProgressSliderStart: () => this.onProgressSliderStart(),
			isMuted: false,
			createProgressControlSlider: () => this.createProgressControlSlider(),
			createVolumeControlSlider: () => this.createVolumeControlSlider(),
			toggleFullScreen: exitOnly => this.toggleFullScreen(exitOnly),
			toggleBrowserFullScreen: exitOnly => this.toggleBrowserFullScreen(exitOnly),
			isBrowserFullScreen: () => this.isBrowserFullScreen(),
			canBrowserFullScreen: () => this.canBrowserFullScreen(),
			isIEEmbedVideo: doc.documentMode < 11 && doc.URL.includes('embed')
		};

		this.stream = {
			autoPlay: !!this.autoPlay,
			isLive: !!this.live,
			isPlaybackInitialized: false,
			state: 'stopped',
			currentTime: 0,
			duration: 0,
			isLoaded: false
		};

		this.escapeEventBinding = event => this.escapeEvent(event);
		this.hasPlugin = true;
		this.id = VBrickPlayerWmvComponent.playerName;
		this.isNativeFullScreenSupport = this.controls.canBrowserFullScreen();
		this.noVideoOverlay = this.getIsNoVideoOverlay();
		this.playState = -1;
		this.skipEscapeEventBinding = event => this.skipEscapeEvent(event);

		if (this.noVideoOverlay) {
			document.body.classList.add('navbar-fixed-top-disabled');
			this.hostElement.nativeElement.classList.add('vb-player-no-video-overlay');
		}

		//attempt to append the video player with our data upon initialization
		this.appendVideoPlayer();

		this.controls.createProgressControlSlider();
		this.controls.createVolumeControlSlider();
		this.onLiveChange(); // init progress slider
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.live) {
			this.onLiveChange();
		}

		if (changes.videoUrl) {
			const prevVideoUrl: string = changes.videoUrl.previousValue;

			if ((!prevVideoUrl && this.videoUrl) || this.videoUrl !== prevVideoUrl) {
				if(this.controls && this.controls.removeCurrentPlayer){
					this.controls.removeCurrentPlayer();
				}

				this.appendVideoPlayer();
			}
		}
	}

	public ngOnDestroy(): void {
		document.body.classList.remove('navbar-fixed-top-disabled');

		if (this.stream.state === 'playing') {
			if (this.stream.isLive && this.onStop) {
				this.onStop.emit({});
			} else if (this.onPause) {
				this.onPause.emit({ timestamp: this.stream.currentTime });
			}
		}

		this.controls.removeCurrentPlayer();
	}

	public currentLocation() {
		return {
			left: (this.stream.currentTime / this.stream.duration) * 100 + '%'
		};
	}

	public pause(): void {
		if (this.player) {
			this.player.controls.pause();

			if (this.onPause) {
				this.onPause.emit({ timestamp: this.stream.currentTime });
			}
		}
	}

	public play(): void {
		if (this.player) {
			this.player.controls.play();
			this.createCurrentPositionInterval();
		}
	}

	public stop(): void {
		if (this.player && this.player.playState !== 0) {
			this.player.controls.stop();

			if (this.onStop) {
				this.onStop.emit({});
			}
		}
	}

	public setMute(): void {
		if (this.player) {
			this.player.settings.mute = this.controls.isMuted = !this.controls.isMuted;
		}
	}

	public startFullScreen(): void {
		const container = document.querySelector('.ie-modal-player');

		window.setTimeout(() => {
			this.fullScreen = true;

			// For presentation cases, inform parent elements the change.
			this.emitFullscreenToggled();

			container.classList.add('ie-modal-player-fs', 'full-screen-video');
			container.classList.remove('ie-modal-player');
		});

		document.body.addEventListener('keyup', this.escapeEventBinding);
		this.addEscListenerForFS();
	}

	public stopFullScreen(): void {
		const container = document.querySelector('.ie-modal-player-fs');

		if (this.fullScreen) {
			window.setTimeout(() => {
				this.fullScreen = false;

				// For presentation cases, inform parent elements the change.
				this.emitFullscreenToggled();

				container.classList.add('ie-modal-player');
				container.classList.remove('ie-modal-player-fs', 'full-screen-video');
			});

			document.body.removeEventListener('keyup', this.escapeEventBinding);
			this.removeEscListenerForFS();
		}
	}

	public toggleClosedCaption(): void {
		if (this.player && this.stream.state === 'playing') {
			this.controls.isClosedCaptionOn = !this.controls.isClosedCaptionOn;

			let url = this.videoUrl;

			if(this.controls.isClosedCaptionOn){
				this.player.closedCaption.captioningID = 'ccdiv';
				url = url.replace('cc=off', 'cc=on_ovl');
			} else {
				this.player.closedCaption.captioningID = '';
				url = url.replace('cc=on_ovl', 'cc=off');
			}

			this.reloadPlayerOnCCChange(url);
		}
	}

	public toggleFullScreen(exitOnly?: boolean): void {
		if (this.controls.canBrowserFullScreen(exitOnly)) {
			const isBrowserFullScreen: boolean = this.controls.isBrowserFullScreen();
			const paused: boolean = this.pauseForFS();

			window.setTimeout(() =>	this.controls.toggleBrowserFullScreen());

			if (paused) {
				this.restartForFS();
			}

			window.setTimeout(() => {
				isBrowserFullScreen ? this.removeEscListenerForFS() : this.addEscListenerForFS();
				this.fullScreenResize(!isBrowserFullScreen);
			}, 1000);
		}
		else {
			if (exitOnly || this.fullScreen) {
				this.stopFullScreen();
			} else {
				this.startFullScreen();
			}
		}
	}

	private addEscListenerForFS(): void {
		window.addEventListener('keydown', this.skipEscapeEventBinding);
	}

	private appendVideoPlayer(): void {
		if (this.videoUrl && this.controls) {
			this.controls.appendVideoPlayerEl(this.videoUrl);
		}
	}

	private appendVideoPlayerEl(url: string): void {
		this.playerPlaceholder.nativeElement.append(this.getObjectTag(url, this.stream.autoPlay));

		this.playState = -1;
		this.player = document.getElementById(this.id);

		(window as any).PlayStateChange = (state: number) => this.onPlayStateChange(state);

		(window as any).StatusChange = () => {
			// Need to define this even though not used.
		};
		this.player.closedCaption.captioningID = '';
		this.player.settings.invokeURLs = false;
		this.player.stretchToFit = '1';

		if (this.stream.autoPlay) {
			if (this.onPlay) {
				this.onPlay.emit({});
			}

			this.createCurrentPositionInterval();
		}

		if (this.onPlayerReady) {
			this.onPlayerReady.emit({ vgAPI: this.getVgAPI() });
		}
	}

	private canBrowserFullScreen(): boolean {
		const doc: any = document;
		const elem = doc.getElementById('player-wrap');
		//elem = null;  /// Not all browsers support full screen mode methods.  To debug old browsers, uncomment the statement.
		return ( elem && (elem.requestFullscreen || elem.msRequestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen));
	}

	private createCurrentPositionInterval(): void {
		if(!this.currentPositionInterval){
			this.currentPositionInterval = window.setInterval(() => {
				if(this.playState !== this.player.playState){
					this.playState = this.player.playState;
					this.onPlayStateChange(this.player.playState);
				}

				this.updateCurrentPosition();
			}, 500);
		}
	}

	private createProgressControlSlider(): void {
		if (this.controls.progressCtrl) {
			this.stream.isPlaybackInitialized = false;

			if (this.controls.progressCtrl.data('ui-slider')) {
				this.controls.progressCtrl.slider('destroy');
			}

			this.controls.progressCtrl.slider({
				orientation: 'horizontal',
				range: 'min',
				max: this.stream.duration || 0,
				min: 0,
				value: 0
			});
		}
	}

	private createVolumeControlSlider(): void {
		if (this.controls.volumeCtrl) {
			if (this.controls.volumeCtrl.data('ui-slider')) {
				this.controls.volumeCtrl.slider('destroy');
			}

			this.controls.volumeCtrl.slider({
				orientation: 'horizontal',
				range: 'min',
				max: 100,
				min: 0,
				value: 100
			});

			this.controls.volumeCtrl.off('slidestop').on('slidestop', (event, ui) => {
				if (this.controls.onVolumeSliderStop) {
					this.controls.onVolumeSliderStop(event, ui);
				}
			});
		}
	}

	private destroyCurrentPositionInterval(): void {
		if(this.currentPositionInterval){
			window.clearInterval(this.currentPositionInterval);
			this.currentPositionInterval = undefined;
		}
	}

	private emitFullscreenToggled(): void {
		if (this.onFullscreenToggled) {
			this.onFullscreenToggled.emit({ isFullscreen: this.fullScreen });
		}
	}

	private escapeEvent(e: JQueryKeyEventObject): void {
		if (e.which === 27) {
			window.setTimeout(() =>	this.stopFullScreen());
		}
	}

	private fullScreenResize(isFullScreen: boolean): void {
		const elem = document.getElementById('player-wrap');

		if ( isFullScreen ) {
			const winWidth = $(window).width();
			const winHeight = $(window).height();
			const offset = 40;
			const width = Math.round ((winHeight - offset) * 16 / 9);

			if ( width < winWidth ) {
				window.setTimeout(() => {
					elem.style.width = width + 'px';
				});
			}
		}
		else {
			window.setTimeout(() => {
				elem.style.width = '100%';
			});
			window.setTimeout(() => {
				elem.style.width = '';
			});
		}
	}

	/**
	 * If the playback options contain a url using a streaming protocol, then our Windows Media plugin will kick in.
	 * WMP12 covers most of our standard codecs through standard  play, but MS didn't bother to implement many of them to work with streaming protocols.
	 * The plugin does not abide by the WMP's windowlessMode setting and will render on top of any HTML content.
	 * @param  Array playbackOptions [description]
	 * @return boolean true if a streaming protocol, so overlaying of content on the video should be disabled.
	 */
	private getIsNoVideoOverlay(): boolean {
		return this.playbackOptions && !!this.playbackOptions.find(option => {
			return option.url.startsWith('vbrtsp') ||
				option.url.startsWith('rtsp') ||
				option.url.startsWith('rtmp') ||
				option.url.startsWith('vbhttp');
		});
	}

	private getObjectTag(url: string, autoStart: boolean): string {
		const isWindowlessVideo: boolean = !this.getIsNoVideoOverlay();

		return `<object id="${this.id}" classid="CLSID:6BF52A52-394A-11d3-B153-00C04F79FAA6" width="100%" height="100%" type="application/x-oleobject">
					<param name="Url" value="${url}">
					<param name="AutoStart" value="${autoStart}">
					<param name="uiMode" value="none">
					<param name="windowlessVideo" value="${isWindowlessVideo}">
			</object>
			<object codeBase="/shared/plugins/VBPlayerComponents.cab#version=6,3,6,0" height="0" width="0" classid="clsid:699E6BEC-7E58-4BA4-835C-DCB5B07BEE22" VIEWASTEXT></object>
			<div id="ccdiv"></div>`;
	}

	private getVgAPI() {
		const vgAPI = {
			toggleSound: () => this.setMute()
		};

		Object.defineProperties(vgAPI, {
			volume: {
				get: () => this.controls.isMuted ? 0 : this.player.settings.volume
			}
		});

		return vgAPI;
	}

	private isBrowserFullScreen(): boolean {
		const doc: any = document;

		return (doc.fullscreenElement || // alternative standard method
			doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement);
	}

	private onLiveChange(): void {
		if (this.controls && this.controls.progressCtrl) {
			this.controls.progressCtrl.off('slidestop').on('slidestop', (event, ui) => {
				if (this.controls.onProgressSliderStop) {
					this.controls.onProgressSliderStop(event, ui);
				}
			});
			this.controls.progressCtrl.off('slidestart').on('slidestart', (event, ui) => {
				if (this.controls.onProgressSliderStart) {
					this.controls.onProgressSliderStart(event, ui);
				}
			});
		}
	}

	private onMediaComplete(){
		this.onStopped();

		if (this.onComplete) {
			this.onComplete.emit({ duration: this.stream.duration });
		}

		this.toggleFullScreen(true);
	}

	private onPaused(): void {
		this.stream.state = 'paused';
		this.destroyCurrentPositionInterval();
	}

	public onPlaybackOptionChange({ playbackOption }): void {
		const isPlaying = this.stream.state === 'playing';
		const isNotLive = !this.stream.isLive;

		//capture playback position
		const playbackPosition: number = this.player.controls.currentPosition;

		if (isPlaying) {
			this.player.controls.stop();
		}

		//apply playback url to the player
		this.player.URL = playbackOption.url;

		if (isPlaying) {
			if (isNotLive) {
				this.restorePlaybackPosition = playbackPosition;
			}

			this.play();
		} else if (isNotLive && playbackPosition) { //paused vod
			//To restore the position as expected, have to trigger play.
			//The play handler will do the job of updating the position and immediately pausing.
			this.restorePlaybackPosition = playbackPosition;
			this.pauseAfterRestore = true;

			this.play();
		}
	}

	private onPlaying(): void {
		if (this.stream.state !== 'playing') {
			if (this.onPlay) {
				this.onPlay.emit({});
			}

			this.stream.state = 'playing';

			this.stream.duration = this.player.currentMedia.duration * 1000;

			this.controls.progressCtrl.slider( 'option', 'max', this.stream.duration);
		}

		if (this.restorePlaybackPosition) {
			this.player.controls.currentPosition = this.restorePlaybackPosition;
			this.restorePlaybackPosition = null;

			if (this.pauseAfterRestore) {
				this.pause();
				this.pauseAfterRestore = false;
			}
		}
	}

	private onPlayStateChange(state: number): void {
		if (this.discardPlayerEvent) {
			return;
		}

		switch(state.toString()){
			case '1':
				this.onStopped();
				break;
			case '2':
				this.onPaused();
				break;
			case '3':
				this.onPlaying();
				break;
			case '8':
				this.onMediaComplete();
				break;
			default:
				break;
		}
	}

	private onProgressSliderStart(): void {
		this.destroyCurrentPositionInterval();
	}

	private onProgressSliderStop(event: JQueryUI.SliderEvent, ui: JQueryUI.Slider): void {
		if (this.player){
			this.player.controls.currentPosition = ui.value / 1000;
			this.createCurrentPositionInterval();
		}
	}

	private onStopped(): void {
		this.stream.state = 'stopped';
		this.destroyCurrentPositionInterval();
		this.stream.currentTime = 0;
		this.controls.progressCtrl.slider('value', 0);
	}

	private onVolumeSliderStop(event: JQueryUI.SliderEvent, ui: JQueryUI.Slider): void {
		this.player.settings.volume = ui.value;
		window.setTimeout(() => {
			this.controls.isMuted = this.player.settings.mute = (ui.value === 0);
		});
	}

	private pauseForFS(): boolean {
		if (this.stream.state === 'playing') {
			this.discardPlayerEvent = true;
			this.stream.isLive ? this.player.controls.stop() : this.player.controls.pause();

			return true;
		}

		return false;
	}

	private reloadPlayerOnCCChange(url: string): void {
		let currentPosition = 0;

		if(!this.stream.isLive){
			currentPosition = this.player.controls.currentPosition;
		}

		this.destroyCurrentPositionInterval();

		this.player.controls.stop();

		this.player.URL = url;

		if (!this.stream.isLive) {
			this.player.controls.currentPosition = currentPosition;
		}

		this.player.controls.play();
	}

	private removeCurrentPlayer(): void {
		this.destroyCurrentPositionInterval();

		if (this.player) {
			if (this.stream.state === 'playing' || this.stream.state === 'paused') {
				this.player.controls.stop();
			}

			if (this.player.close) {
				this.player.close();
			}
			delete (window as any).PlayStateChange;
			delete (window as any).StatusChange;
			this.player.URL = '';
			this.player = null;
		}

		this.playerPlaceholder.nativeElement.innerHTML = '';
	}

	private removeEscListenerForFS(){
		window.removeEventListener('keydown', this.skipEscapeEventBinding);
	}

	private restartForFS(): void {
		this.player.controls.play();

		window.setTimeout(() => {
			this.discardPlayerEvent = false;
		}, 50);
	}

	private skipEscapeEvent(e: JQueryKeyEventObject): void {
		if (e.which === 27) {
			e.preventDefault();

			window.setTimeout(() => {
				this.removeEscListenerForFS();
				this.fullScreenResize(false);
			}, 50);
		}
	}

	public onPlaybackOptionMenuToggle({ isOpen }): void {
		this.isPlaybackOptionsMenuOpen = isOpen;
	}

	private toggleBrowserFullScreen(exitOnly): boolean {
		let called = false;
		const doc: any = document;
		const elem: any = doc.getElementById('player-wrap');
		//elem = null;  /// Not all browsers support full screen mode methods.  To debug old browsers, uncomment the statement.

		if (!elem) {
			return called;
		}
		else if (!exitOnly && !doc.fullscreenElement && // alternative standard method
			!doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement ) { // current working methods
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
				called = true;
			} else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen();
				called = true;
			} else if (elem.mozRequestFullScreen) {
				elem.mozRequestFullScreen();
				called = true;
			} else if (elem.webkitRequestFullscreen) {
				elem.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
				called = true;
			}
		} else {
			if (doc.exitFullscreen) {
				doc.exitFullscreen();
				called = true;
			} else if (doc.msExitFullscreen) {
				doc.msExitFullscreen();
				called = true;
			} else if (doc.mozCancelFullScreen) {
				doc.mozCancelFullScreen();
				called = true;
			} else if (doc.webkitExitFullscreen) {
				doc.webkitExitFullscreen();
				called = true;
			}
		}
		return called;
	}

	private updateCurrentPosition(): void {
		if (!this.stream.isLive) {
			const timeMs: number = this.player.controls.currentPosition * 1000;

			this.stream.currentTime = timeMs;
			this.controls.progressCtrl.slider('value', timeMs);

			if (this.onPlaybackPositionUpdated) {
				this.onPlaybackPositionUpdated.emit({ time: timeMs });
			}

			// The Player is just not sending the stop event at the end of the stream and it looks like playing. Happens only for rtsp streams.
			if(this.stream.state === 'playing' && this.player.currentMedia.duration !== 0) {
				if (this.player.currentMedia.duration === this.player.controls.currentPosition){
					this.player.controls.stop();
				}

				if (Math.abs(this.player.currentMedia.duration - this.player.controls.currentPosition) < 0.5) {
					window.setTimeout(() => this.toggleFullScreen(true), 1200);
				}
			}
		}
	}
}
