<div class="webex-import" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"> <!--Prevents the upload dropdown from being closed while interacting with this dialog-->
	<div [hidden]="!isLoading">
		<vb-toolbar edge-padding class="section-header">
			<div flex="fill" class="padding-left-32">
				<h1 id="modal-title">{{ 'Loading' | translate }}</h1>
			</div>
		</vb-toolbar>

		<div class="modal-body" disable-ng-animate [hidden]="!isLoading">
			<vb-loading-spinner [size]="'medium'" [block]="true"></vb-loading-spinner>
		</div>
	</div>
	<form [hidden]="!showWebexSitesList" name="webexSitesListForm" #webexSitesListForm>
		<vb-toolbar edge-padding class="section-header">
			<div flex="fill" class="padding-left-32">
				<h1>{{ 'Uploads_Import_WebEx_SiteSelection_Header' | translate }}</h1>
			</div>
		</vb-toolbar>
		<div class="modal-body input-fields">
			<label>{{ 'Uploads_Import_WebEx_SiteSelection_Notes' | translate }}</label>
			<vb-ng-select-wrap uiControl>
				<ng-select [items]="webexSites" [(ngModel)]="selectedSite" bindLabel="name" [placeholder]="'Uploads_Import_WebEx_SiteSelection' | translate" name="uiSelectSite" labelForId="uiSelectSite" required #uiSelectSite="ngModel">
					<ng-template ng-option-tmp let-item="item">
						<div class="select-choices-row-items">
							<span>
								{{ item.name }} ({{ item.url }})
							</span>
						</div>
					</ng-template>
				</ng-select>
			</vb-ng-select-wrap>
		</div>
		<div class="modal-footer">
			<button type="button" vbBtnWhite (click)="close()">
				{{ 'Cancel' | translate }}
			</button>
			<button class="margin-left-5" type="button" vbBtnPrimary (click)="onSiteSelect()" [disabled]="webexSitesListForm.invalid">
				{{ 'Next' | translate }}
			</button>
		</div>
	</form>

	<div [hidden]="!(!isLoading && !showWebexSitesList)">
		<form name="webexImportForm" class="form-horizontal" (submit)="import()" class="padding-full-5">

			<vb-toolbar edge-padding class="section-header">
				<div flex="fill">
					<h1>{{ 'Uploads_Import_SelectVideos' | translate }}</h1>
				</div>
				<div [hidden]="!webExRecordings?.length">
					<small>{{ webExRecordings?.length }} {{ 'Media_Videos' | translate }}</small>
				</div>
			</vb-toolbar>

			<div class="modal-body">
				<vb-grid-list [ngClass]="styles.importWebexGridlist" class="white-items" [emptyMsg]="'Uploads_Import_WebEx_NoRecordings' | translate">

					<header header>
						<vb-ui-checkbox class="selectionColumn" (ngModelChange)="syncSelectionHeaderModel()" [(ngModel)]="isSelectAll" name="selectionHeader">
						</vb-ui-checkbox>
						<div flex="fill">{{ 'Name' | translate }}</div>
						<div class="column-center">{{ 'Imported' | translate }}</div>
						<div>{{ 'Created' | translate }}</div>
						<div class="column-right">{{ 'Duration' | translate }}</div>
						<div class="column-right">{{ 'Size' | translate }}</div>
						<div class="column-center">{{ 'Media_Integrations_WebexMeetings_Transcript_Available' | translate }}</div>
					</header>

					<vb-grid-row-collection vbGridRowCollection>
						<vb-grid-list-row *ngFor="let recording of webExRecordings; trackBy trackByIndex; let index = index">
							<vb-ui-checkbox (ngModelChange)="onRowSelectionChange(recording)" [(ngModel)]="recording.selected" [disabled]="recording.isImported" name="selectedRecording{{index}}">
							</vb-ui-checkbox>
							<vb-grid-list-column flex="fill">{{recording.topic}}</vb-grid-list-column>
							<vb-grid-list-column class="column-center">
								<span class="glyphicons" [ngClass]="{'circle_ok': recording.isImported}"></span>
							</vb-grid-list-column>
							<vb-grid-list-column>{{recording.createTime|date:'medium'}}</vb-grid-list-column>
							<vb-grid-list-column class="column-right">{{recording.durationInMs|vbTimespan}}</vb-grid-list-column>
							<vb-grid-list-column class="column-right">{{recording.sizeBytes|fileSize:2}}</vb-grid-list-column>
							<vb-grid-list-column class="column-center">{{recording.transcriptionFileUrl ? "Yes" : "No" | translate}}</vb-grid-list-column>
						</vb-grid-list-row>
					</vb-grid-row-collection>
				</vb-grid-list>
			</div>

			<div class="modal-footer">
				<button type="button" vbBtnPrimary class="float-left" *ngIf="webexSites.length &gt; 1" (click)="onBackToWebexSitesList()">
					&lt; {{ 'Back' | translate }}
				</button>
				<button type="button" vbBtnWhite (click)="close()">{{ 'Cancel' | translate }}</button>
				<button type="submit" vbBtnPrimary [disabled]="!selectedWebExImports.length">
					{{ 'Import' | translate }}
					<span [hidden]="!selectedWebExImports.length"> {{ selectedWebExImports.length }} {{ 'Media_Videos' | translate }}</span>
				</button>
			</div>
		</form>
	</div>
</div>
