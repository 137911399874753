import {
	AfterContentInit,
	AfterViewInit,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	LOCALE_ID,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';

import { AgGridAngular, AngularFrameworkComponentWrapper, AngularFrameworkOverrides } from '@ag-grid-community/angular';
import type { Column, ColumnState, ColumnEventType, Module } from '@ag-grid-community/core';

import { TranslateService } from '@ngx-translate/core';

import { Subscription, merge } from 'rxjs';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { BrandingSettings, ThemeSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import { ISidebarConfig } from 'rev-shared/ui/sidebarLayoutAngular/ISidebarConfig';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { isString } from 'rev-shared/util';

import { ActionIconsRendererComponent } from './cellRenderers/ActionIconCellRenderer.Component';
import { ActionMenuButtonCellRendererComponent } from './cellRenderers/ActionMenuButtonCellRenderer.Component';
import { ButtonGroupCellRendererComponent } from './cellRenderers/ButtonGroupCellRenderer.Component';
import { CheckboxCellRendererComponent } from './cellRenderers/CheckboxCellRenderer.Component';
import { DUMMY_TOOLTIP, VbUiDataGridTooltipComponent } from './tooltip/VbUiDataGridTooltip.Component';
import { HeaderSelectAllCheckboxRendererComponent } from './cellRenderers/HeaderSelectAllCheckboxRenderer.Component';
import { HighlightCellRendererComponent } from './cellRenderers/HighlightCellRenderer.Component';
import { IVbUiDataGridContext } from './IVbUiDataGridContext';
import { IconCellRendererComponent } from './cellRenderers/IconCellRenderer.Component';
import { LinkCellRendererComponent } from './cellRenderers/LinkCellRenderer.Component';
import { ProfilePictureCellRendererComponent } from './cellRenderers/ProfilePictureCellRenderer.Component';
import { TogglesCellRendererComponent } from './cellRenderers/TogglesCellRenderer.Component';
import { VbUiDataGridColumnPickerGroup } from './columnPicker/VbUiDataGridColumnPickerGroup.Component';

import { IVbUiDataGridColDef } from './columns/IVbUiDataGridColDef';

import styles from './VbUiDataGrid.Component.module.less';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';

export const COMPONENT_HOST = {
	'[class]': 'hostClass',
	layout: 'row',
	'layout-wrap': 'false'
};

export const COMPONENT_PROVIDERS = [
	AngularFrameworkComponentWrapper,
	AngularFrameworkOverrides
];

export const COMPONENT_TEMPLATE = `
	<vb-ui-sidebar
		*ngIf="columnPickerEnabled"
		[ngClass]="styles.columnPickerSidebar"
		[isMobileLayoutDisabled]="true"
		[openWidthPx]="250"
		[sidebarConfig]="sidebarConfig">

		<div slot="panelBody">
			<vb-ui-data-grid-column-picker
				[columns]="allColumns"
				[columnApi]="columnApi"
				[groups]="columnPickerGroups">
			</vb-ui-data-grid-column-picker>
		</div>

	</vb-ui-sidebar>
`;

export interface IVbUiDataGridState {
	columns: ColumnState[];
	quickFilter: string;
	sort: Array<{ colId: string; sort: 'asc' | 'desc'; }>;
}

/**
 * vb-ui-data-grid
 * Extends the @ag-grid-community/angular grid with some of our desired core functionality:
 * - Lazy loading of ag-grid module dependencies.
 * - Defining common renderers for simple reuse.
 * - Sizing columns to fit.
 * - A ui-field shorthand input for populating getRowNodeId().
 * - Populating the grid context so that tools and information may easily be passed around (such as a getTranslation() function).
 */
@Component({
	selector: 'vb-ui-data-grid',
	template: COMPONENT_TEMPLATE,
	host: COMPONENT_HOST,
	providers: COMPONENT_PROVIDERS,
	encapsulation: ViewEncapsulation.None
})
export class VbUiDataGridComponent extends AgGridAngular<any, IVbUiDataGridColDef> implements OnChanges, OnInit, AfterContentInit, AfterViewInit, OnDestroy {
	@Input() public columnPickerEnabled: boolean;
	@Input() public sizeColumnsToFit: boolean = true;
	@Input() public autoSizeAllColumns: boolean;
	@Input() public restoreState: IVbUiDataGridState;
	@Input() public uidField: string; // Field containing a uid string. Shorthand for populating a getRowNodeId() getter.
	@Input() public themed: boolean;
	@Input() public defaultColumnSort: { colId: string; sort: string; };
	@Input() public fullWidthCellRendererFunc: any;

	@Output() public currentStateChange = new EventEmitter<IVbUiDataGridState>();

	@ContentChildren(VbUiDataGridColumnPickerGroup) public columnPickerGroups: QueryList<VbUiDataGridColumnPickerGroup>;

	public readonly styles = styles;

	public allColumns: Column[];
	public hostClass: string = `ag-theme-material box-block ${this.styles.root}`;
	public themeStyleOverrides: IRules;
	protected isDestroyed: boolean;
	protected subscriptions: Subscription[] = [];

	protected moduleLazyLoadPromise: Promise<void>;

	public sidebarConfig: ISidebarConfig = {
		buttons: [
			{
				iconClass: `vb-icon vb-icon-column-picker ${this.styles.columnPickerIcon}`,
				id: 'columnPicker',
				label: this.translateService.instant('UI_DataGrid_ColumnPicker_SidebarHeader'),
				visible: true
			}
		],
		closeBtnHidden: true
	};

	constructor(
		private elementDef: ElementRef,
		viewContainerRef: ViewContainerRef,
		@Inject(AngularFrameworkOverrides) angularFrameworkOverrides: AngularFrameworkOverrides,
		@Inject(AngularFrameworkComponentWrapper) frameworkComponentWrapper: AngularFrameworkComponentWrapper,
		protected translateService: TranslateService,
		private ThemeService: ThemeService,
		public zone: NgZone,
		@Inject(LOCALE_ID) private localeId: string
	) {
		super(elementDef, viewContainerRef, angularFrameworkOverrides, frameworkComponentWrapper);
	}

	public ngOnInit(): void {
		this.moduleLazyLoadPromise = this.lazyLoadGridModules();

		this.subscriptions.push(
			this.firstDataRendered.subscribe(() => this.onFirstDataRenderedInternal()),
			this.gridSizeChanged.subscribe(() => this.onSizeChanged()),
			this.initGridStateChange(),
			this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings))
		);
	}

	public ngAfterContentInit(): void {
		// Apply translations for ag-grid labels
		this.localeText = {
			loadingOoo: this.translateService.instant('Loading'),
			noRowsToShow: this.translateService.instant('UI_Chart_NoDataAvailable')
		};

		// Context allows us to pass common things into our grid code such as renderers.
		this.context = {
			getQuickFilterText: () => this.quickFilterText,
			getTranslation: (key: string | string[]) => this.translateService.instant(key),
			localeId: this.localeId
		} as IVbUiDataGridContext;

		// Short-hand convenience feature for populating a getRowNodeId() function.
		if (this.uidField && !this.getRowId) {
			this.getRowId = data => data[this.uidField];
		}

		this.isFullWidthRow = params => params?.rowNode?.data?.fullWidth;
		this.fullWidthCellRenderer = this.fullWidthCellRendererFunc;

		// Make commonly used cell renderers readily available by name/id.
		this.components = {
			actionMenuButton: ActionMenuButtonCellRendererComponent,
			buttonGroup: ButtonGroupCellRendererComponent,
			actionIcons: ActionIconsRendererComponent,
			checkbox: CheckboxCellRendererComponent,
			icon: IconCellRendererComponent,
			link: LinkCellRendererComponent,
			highlightText: HighlightCellRendererComponent,
			gridTooltip: VbUiDataGridTooltipComponent,
			toggles: TogglesCellRendererComponent,
			headerSelectAllCheckbox: HeaderSelectAllCheckboxRendererComponent,
			profilePicture: ProfilePictureCellRendererComponent
		};

		//To avoid warning - invalid colDef property '_ngContext'
		this.suppressPropertyNamesCheck = true;
		this.applyDefaultColConfig();
	}

	public ngAfterViewInit() {
		return this.moduleLazyLoadPromise
			.then(() => {
				if (this.isDestroyed) {
					return;
				}

				this.applyConfigToColumns();
				this.applyDefaultColConfig();

				this.applyRestoreState();

				// capture quickFilterText to silence feature warning
				const qf = this.quickFilterText;
				this.quickFilterText = null;

				super.ngAfterViewInit();

				// restore
				this.quickFilterText = qf;

				if (this.restoreState) {
					this.api.applyColumnState({ state: this.restoreState.columns });
				}

				this.allColumns = this.api.getColumns();

				if (this.defaultColumnSort) {
					this.api.applyColumnState({
						state: [
							this.defaultColumnSort as ColumnState
						]
					});
				}
			});
	}

	public ngOnChanges(changes: any): void {
		if (changes.columnDefs) {
			this.applyConfigToColumns();
		}
		super.ngOnChanges(changes);
	}

	public ngOnDestroy(): void {
		this.isDestroyed = false;
		this.resetCssVariables();

		this.subscriptions.forEach(sub => sub.unsubscribe());
		this.subscriptions = null;
	}

	private applyCssRules(brandingSettings: BrandingSettings): void {
		if (!this.themed) {
			return;
		}
		this.setCSSVariables(brandingSettings);
	}

	private setCSSVariables(brandingSettings: BrandingSettings): void {
		const gridBrandings = this.getBrandingMapping(brandingSettings?.themeSettings);

		Object.keys(gridBrandings).forEach(key => this.elementDef.nativeElement.style.setProperty(key, gridBrandings[key]));
	}

	private resetCssVariables() {
		Object.keys(this.getBrandingMapping()).forEach(key => this.elementDef.nativeElement.style.setProperty(key, ''));
	}


	private getBrandingMapping(theme?: ThemeSettings): { [key: string]: string; } {
		const themeSettings = theme || {} as ThemeSettings;
		return {
			'--ag-background-color': themeSettings.primaryColor,
			'--ag-foreground-color': themeSettings.primaryFontColor,
			'--ag-secondary-foreground-color': themeSettings.primaryFontColor,
			'--ag-header-background-color': themeSettings.primaryColor,
			'--ag-header-foreground-color': themeSettings.primaryFontColor,
			'--ag-header-cell-hover-background-color': themeSettings.primaryColor,
			'--ag-row-hover-color': themeSettings.primaryColor,
			'--vb-ui-ag-grid-container-scroll-color': themeSettings.primaryFontColor,
			'--ag-row-border-color': themeSettings.primaryFontColor,
			'--ag-border-color': themeSettings.primaryFontColor,
			'--ag-selected-row-background-color': themeSettings.primaryColor,
			'--vb-ui-ag-grid-header-tooltip-icon-color': themeSettings.primaryFontColor
		};
	}

	public getCurrentState(): IVbUiDataGridState {
		const columns = this.api.getColumnState();
		const sort = columns?.map(column => column.sort && { colId: column.colId, sort: column.sort }).filter(Boolean);

		return {
			columns,
			quickFilter: this.quickFilterText,
			sort
		};
	}

	@HostBinding('class.vbUiGridAutoHeight')
	public get isAutoHeight(): boolean {
		return this.domLayout === 'autoHeight';
	}

	protected get isClientSideModel(): boolean {
		return !this.rowModelType;
	}

	protected applyRestoreState(): void {
		if (this.restoreState) {
			this.columnDefs.forEach(col => {
				if (this.restoreState.sort) {
					const sortMatch = this.restoreState.sort.find(sortCol => sortCol.colId === (col as IVbUiDataGridColDef).field);

					(col as IVbUiDataGridColDef).sort = sortMatch?.sort;
				}
			});

			if (isString(this.restoreState.quickFilter)) {
				this.quickFilterText = this.restoreState.quickFilter;
			}
		}
	}

	protected getAdditionalModules(): Promise<Module[]> {
		return Promise.resolve([]);
	}

	private getClientSideRowModel(): Promise<Module> {
		return import(/* webpackChunkName: "ag-grid-client-side-row-model" */ '@ag-grid-community/client-side-row-model')
			.then(clientSideExports => clientSideExports.ClientSideRowModelModule);
	}

	protected initGridStateChange(): Subscription {
		return merge(
			this.columnResized,
			this.columnVisible,
			this.filterChanged,
			this.sortChanged
		)
			.subscribe(() => this.currentStateChange.emit(this.getCurrentState()));
	}

	protected onFirstDataRenderedInternal(): void {
		if (this.sizeColumnsToFit && !this.restoreState) {
			this.api.sizeColumnsToFit();
		}
		if (this.autoSizeAllColumns) {
			this.columnApi.autoSizeAllColumns();
		}
	}

	protected onSizeChanged(): void {
		if (this.sizeColumnsToFit && !this.columnPickerEnabled) {
			this.api.sizeColumnsToFit();
		}
		if (this.autoSizeAllColumns) {
			this.columnApi.autoSizeAllColumns();
		}
	}

	public setQuickFilterText(value: string, ignoreRefresh?: boolean): void {
		const currentQuickFilterText: string = this.quickFilterText || '';

		if (currentQuickFilterText === value) {
			return;
		}

		this.quickFilterText = value;

		if (!ignoreRefresh) {
			this.api.setQuickFilter(value);
			this.api.getColumns().forEach(col => {
				const colDef = col.getColDef();
				const filterEnable = col.isVisible() && colDef.filter ? colDef.colId || colDef.field : undefined;
				if (filterEnable) {
					col.setFilterActive(!!this.quickFilterText, 'filterChanged' as ColumnEventType);
				}
			});
			this.api.refreshCells({ force: true });
		}
	}

	private getInfiniteRowModel(): Promise<Module> {
		return import(/* webpackChunkName: "ag-grid-infinite-row-model" */ '@ag-grid-community/infinite-row-model')
			.then(infiniteRowModelExports => infiniteRowModelExports.InfiniteRowModelModule);
	}

	private getRowModel(): Promise<Module> {
		return this.isClientSideModel ?
			this.getClientSideRowModel() :
			this.getInfiniteRowModel();
	}

	private lazyLoadGridModules(): Promise<void> {
		return Promise.all([
			this.getRowModel(),
			this.getAdditionalModules()
		])
			.then(([rowModelModule, additionalModules]) => {
				this.modules = [rowModelModule, ...additionalModules];
			});
	}

	private addDefaultTooltipConfigToColumn(colDef: IVbUiDataGridColDef): void {
		colDef.headerClass = colDef.headerTooltip && colDef.headerTooltip !== DUMMY_TOOLTIP
			? `${colDef.headerClass || ''} ${this.defaultColDef?.headerClass || ''} ${styles.hasHeaderTooltipValue}`.trim()
			: colDef.headerClass;
		colDef.headerTooltip = colDef.headerTooltip || DUMMY_TOOLTIP;
		colDef.tooltipComponent = colDef.tooltipComponent || 'gridTooltip';
	}

	private applySortingOrderReversedToColumn(colDef: IVbUiDataGridColDef): void {
		if (colDef.sortingOrderReversed) {
			colDef.sortingOrder = ['desc', 'asc'];
		}
	}

	private applyColumnFilter(colDef: IVbUiDataGridColDef): void {
		colDef.filter = colDef.filter !== false ? true : colDef.filter;

		if (!colDef.filter) {
			colDef.getQuickFilterText = () => '';
		}
	}

	private applyDefaultColConfig(): void {
		this.defaultColDef = {
			...this.defaultColDef,
			lockVisible: true,
			cellDataType: false,
			suppressMenu: true,
			sortable: false
		};
	}

	private applyConfigToColumns(): void {
		this.columnDefs?.forEach(col => {
			this.applySortingOrderReversedToColumn(col);
			this.addDefaultTooltipConfigToColumn(col);
			this.applyColumnFilter(col);
		});
	}

}
