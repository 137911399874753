import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';

import { VbUiColorSelectorComponent } from './VbUiColorSelector.Component';

const declarations = [
	VbUiColorSelectorComponent
];

@NgModule({
	declarations,
	exports: declarations,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		FormsModule,
		TranslateModule,
		ValidationAngularModule,
		ValidationModule
	]
})
export class VbUiColorSelectorModule {}
